import FEngine from "db-lgtv-focus-engine"
// 全局使用的函数
import timestamp from "@/utils/timestamp"

export default {
  install(Vue) {
    Vue.prototype.$engine = new FEngine()
    // 时间戳转日期
    Vue.prototype.$timestamp = timestamp
  }
}
