import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const modulesFiles = require.context("./modules", true, /\.js$/)
// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1")
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

export default new Vuex.Store({
  modules: {
  },
  state: {
    userInfo: {} // 用户信息
  },
  mutations: {
    SET_USERINFO(state, data) {
      state.userInfo = data
    }
  },
  actions: {
    FETCH_USERINFO({ commit, dispatch }, userInfo) {
    }
  }
})
