<template>
  <div class="progress-bar-wrap">
    <div class="progress-content">
      <div class="progress-play" :style="{ width: `${progressWidth}%` }"></div>
      <div class="progress-move-time" :style="{ left: `calc((${progressWidth}% - 50px` }">
        <div class="move-time-num">{{ currentTimeFormat }}</div>
        <span class="cut-line">/</span>
        <div class="total-time-num">{{ durationFormat }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'progress-bar',
    props: {
      currentTime: {
        type: Number,
        default: 0
      },
      duration: {
        type: Number,
        default: 0
      },
      isPaused: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return{
        page_config: {

        },
        page_status: {
          isForwardBackwardMode: false // 是否开启快进快退模式
        },
        currentTimeFormat: "00:00:00",
        durationFormat: "00:00:00",
        progressWidth: 0
      }
    },
    mounted() {
      this.addListenerFun()
      this.$once('hook:beforeDestroy', () => {
        this.removeListenerFun()
      })
    },
    methods: {
      /* 监听 start */
      // 添加监听
      addListenerFun(player) {
        document.addEventListener("keyup", this.handleKeyUpDirection)
        document.addEventListener("keydown", this.handleKeyDownDirection)
      },
      // 移除监听
      removeListenerFun(player) {
        document.removeEventListener("keyup", this.handleKeyUpDirection)
        document.removeEventListener("keydown", this.handleKeyDownDirection)
      },
      // 监听键盘的左右方向键的keyup
      handleKeyUpDirection(e) {
        let keycode = e.keyCode;
        this.page_status.isForwardBackwardMode = false
        if (keycode == 37 || keycode == 39) {
           let currentTime = new Number(
             this.duration * (this.progressWidth / 100)
           ).toFixed(2);
           if (!isNaN(currentTime)) {
              //给播放器赋值当前时间
              this.$emit("onForwardBackward", false, currentTime)
           }
        }
      },
      // 监听键盘的左右方向键的keydown
      handleKeyDownDirection(e) {
        let keycode = e.keyCode
        this.page_status.isForwardBackwardMode = true
        this.$emit("onForwardBackward", true)
        if (keycode == 37) {
          // 按左键->后退
          this.handleProgress(false)
        } else if (keycode == 39) {
          // 按右键->前进
          this.handleProgress(true)
        }
      },
      /**
       * 进度条前进后退(该逻辑只计算进度条的百分比，当按键up事件时在将该进度对应的currentTime赋值给播放器控件，并播放相应currentTime的音频)
       */
      handleProgress: function (isRight) {
        let widthFixed = new Number(this.progressWidth).toFixed(2);
        this.$emit("onHandleProgress", isRight);
        if (!isNaN(widthFixed)) {
          if (isRight) {
            if (widthFixed >= 100) {
              this.progressWidth = 100;
            } else {
              this.progressWidth = parseFloat(widthFixed) + 1;
            }
          } else {
            if (widthFixed <= 0) {
              this.progressWidth = 0;
            } else {
              this.progressWidth = widthFixed - 1;
            }
          }
        }
      },
      /* 监听 end */
      /**
       * 通过时间戳得到00:00:00格式的时间值
       * @param {*} time
       * @returns
       */
      getLegalTime(time) {
        var hour = Math.floor(time / 3600);
        if (hour < 10) {
          hour = `0${isNaN(hour) ? "0" : hour}`;
        }
        var other = time % 3600;
        var minute = Math.floor(other / 60);
        if (minute < 10) {
          minute = `0${isNaN(minute) ? "0" : minute}`;
        }
        var second = (other % 60).toFixed(0);
        if (second < 10) {
          second = `0${isNaN(second) ? "0" : second}`;
        }
        return hour + ":" + minute + ":" + second;
      }
    },
    watch: {
      duration(n, o) {
        this.durationFormat = this.getLegalTime(n);
      },
      currentTime(n, o) {
        this.currentTimeFormat = this.getLegalTime(n);
        if (this.page_status.isForwardBackwardMode == false) {
          let rateProgress = (this.currentTime / this.duration) * 100;
          this.progressWidth = rateProgress;
        }
      },
      progressWidth(n, o) {
        let audioTime = ((n / 100) * this.duration).toFixed(2);
        this.currentTimeFormat = this.getLegalTime(audioTime);
      }
    }
  }
</script>

<style scoped lang="less">
  @import "index";
</style>