<template>
  <div class="home-wrap">
    <div class="header">
      <div class="title">Community</div>
      <div db-leaf @ok="handlePageJump"  class="r-info">
        <div class="label">The content comes from </div>
        <div class="link">tvsbook.com</div>
      </div>
    </div>
    <div class="content">
      <div class="list" db-trunk @touchBottom="handleTouchBottom">
        <div
          db-leaf
          @ok="handleClick(item)"
          v-for="(item, index) in page_data.listData"
          :key="index"
          class="list-item">
          <div class="item-img-box">
            <img class="img" :src="item.pic" alt="商品图">
          </div>
          <div class="item-info-box">
            <div class="block-l">
              <img class="author-avatar" :src="item.author_heading" alt="">
            </div>
            <div class="block-r">
              <div class="title">{{item.title}}</div>
              <div class="author">{{item.author_name}}</div>
              <div class="num-read">{{item.readnum}} views</div>
            </div>
            <!--<div class="block block-sku">
              <div class="right">
                <div class="num-down">
                  <div class="icon">
                    <svg class="icon_down" viewBox="0 0 24 24">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                          <polyline class="icon_down_ico" points="12 5 12 19 5 12 12 19 19 12"/>
                      </g>
                    </svg>
                  </div>
                  <div class="text">{{item.downnum }}</div>
                </div>
                <div class="num-read">
                  <div class="icon">
                    <svg class="icon_read" viewBox="0 0 24 24">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                          <path class="icon_read_ico1" d="M3,12 C4.86396103,7.02943725 10.4044373,4.51103897 15.375,6.375 C17.9743103,7.34974138 20.0252586,9.40068966 21,12 L21,12 L21,12 C19.136039,16.9705627 13.5955627,19.488961 8.625,17.625 C6.02568966,16.6502586 3.97474138,14.5993103 3,12 L3,12 L3,12 Z"/>
                          <circle class="icon_read_ico2" cx="12" cy="12" r="4"/>
                      </g>
                    </svg>
                  </div>
                  <div class="text">{{item.readnum }}</div>
                </div>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  fetchDetailsData
} from "@/services"
import {
  generatePublicParam 
} from '@/utils/publicPrarams'
import router from '@/router'
export default {
  name: 'home',
  components: {
  },
  data() {
    return {
      page_data: {
        listData: []
      },
      page_config:{
        pageSize: 5,
        page: 1
      }
    }
  },
  created() {
    let isEmpty = true
    Object.keys(window.publicParams).forEach(function(x) {
        if(window.publicParams[x] == null || window.publicParams[x] == ""){
          isEmpty = false
        }
    })
    if(isEmpty){
      this.fetchPageData(true)
    }
  },
  mounted() {
  },
  methods: {
    handlePageJump() {
      // window.location.href = 'https://www.tvsbook.com/'
      window.open('https://www.tvsbook.com/')
    },
    formatTimestamp(date){
      const formateDateArr = this.$timestamp(date).split(' ')[0].split("/")
      const monthEnglish = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Spt","Oct","Nov","Dec"]
      let year = formateDateArr[0]
      let month = monthEnglish[formateDateArr[1]]
      let day = formateDateArr[2]
      return month + ' ' + day + ',' + year
    },
    // 跳转详情页面
    handleClick (item) {
      let downUrlArr = item.app_downurl.split('.')
      let downUrlArrLength = downUrlArr.length
      let appTitle = ''
      if (item.app_title.indexOf(".") != -1) {
        appTitle = item.app_title
      } else {
        appTitle = item.app_title + '.' + downUrlArr[downUrlArrLength-1]
      }
      item.app_packname = item.app_packname ? item.app_packname : ''
      // window.location.href = '/#/detail-player?page=' + item.page + '&id=' + item.id
      // 1. 告知安卓包名
      // 2. 安卓告知我状态
      if (item.app_packname){
        community.getCurrentFileInfo(appTitle, item.app_packname, item.page, item.id)
      } else {
        window.location.href = '/#/detail-player?page=' + item.page + '&id=' + item.id
      }
    },
    // 分页加载
    handleTouchBottom() {
       if (
        this.page_data.listData.length <
        this.page_config.pageSize * this.page_config.page
      ) {
        // 当前页面已经是最后一页 再次加载本页
      } else {
        this.page_config.page++
        this.fetchPageData(false)
      }
    },
    // 格式化页面数据listData
    formateListData(list) {
      if (list) {
        list.forEach((_i, _index) => {
          _i.page = this.page_config.page
          // _i.id = this.page_config.page + _index.toString()
        })
      }
      
      return list
    },
		async fetchPageData (loading) {
      await fetchDetailsData(
        {
          page: this.page_config.page
        },
        loading
      ).then(res => {
        if (res.data.list != undefined){
          let listArr = this.formateListData(res.data.list)
          this.page_data.listData = [...this.page_data.listData, ...listArr]
        }
        
      })
		}
  }
}
</script>
<style scoped lang="less">
@import "index";
</style>
