import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/page/home-video/index'

Vue.use(VueRouter)

const pageFiles = require.context("../page", true, /\index.vue$/)
const routes = pageFiles.keys().map((modulePath) => {
  // set './app.js' => 'app'
  const module = modulePath.replace(/^\.\/(.*)/, "$1").split('/')
  let moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1").split('/')[0]
  let routePath = moduleName
  // console.log(module)
  const value = pageFiles(modulePath)
  if(module[1] && module[1] !== 'index.vue') {
    routePath = moduleName + '/' + module[1]
    moduleName = module[1]
  }
  return {
    path: '/' + routePath,
    name: moduleName,
    component: value.default,
    meta: {
      keepAlive: false
    }
  }
})

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Home
    },
    ...routes
  ]
})
// 解决vue-router在3.0版本以上重复菜单报错问题
// const VueRouterPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(to) {
//   return VueRouterPush.call(this, to).catch((err) => err)
// }

export default router
