import Vue from 'vue'
import axios from 'axios'
import router from "@/router";
import qs from 'qs'
import Global from '@/configs/global'
import {
  ERROR as CODE_ERROR,
  NEED_LOGIN
} from '@/constants/response-code'
import {
  generatePublicParam 
} from '@/utils/publicPrarams'
import {
  encryptParams
} from '@/utils/encryption'
let vue = new Vue()
const BASE_URL = process.env.VUE_APP_API
class Axios {
  axios = axios.create({
    baseURL: BASE_URL,
    timeout: 8000,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      // "Access-Control-Allow-Headers": "mac"
    },
  });
  //  channel: '', // 渠道
  //       packagename: '', // 包名
  //       mac: '', // 有线MAC地址
  //       wifimac: '', // 无线MAC地址
  //       uuid: '', // 设备id
  //       brand: '', // 品牌
  //       rommodel: '', // 型号
  //       lang: 'en', // 语言（cn  简体中文  tc 繁体中文 ,en  英文）
  //       vcode: '', // 桌面code
  //       vname: '' // 桌面版本
  constructor() {
    // 初始化axios拦截器
    this.init();
  }
  // 格式化参数, 参数非空排除, 排除空字符串以及null
  formateParams(data, formateData = {}) {
    if (data) {
      Object.keys(data).forEach(item => {
        if (
          data[item] !== "" &&
          data[item] !== null &&
          data[item] !== undefined
        )
          formateData[item] = data[item];
      });
    }
    return formateData;
  }
  // 将参数加入到请求headers中
  enterParamsToHeaders(publicParams, headers) {
    // 加密参数
    let encryptParamsObj = this.encryptParamsObj(publicParams)
    if (encryptParamsObj) {
      Object.keys(encryptParamsObj).forEach(item => {
        if (
          encryptParamsObj[item] !== "" &&
          encryptParamsObj[item] !== null &&
          encryptParamsObj[item] !== undefined
        )
          headers[item] = encryptParamsObj[item]
      })
    }
  }
  // 将参数加入到请求body中
  enterParamsToBody(publicParams, originalParams, config) {
    let encryptParamsObj = null
    if (publicParams) {
      publicParams.ip ? JSON.stringify(publicParams.ip) : '' // 系列化ip
      // 合并参数
      let newParams = this.mergeParams(publicParams, originalParams)
      // 加密参数
      encryptParamsObj = this.encryptParamsObj(newParams)
      // let encryptParamsObj = newParams
      
    } else {
      encryptParamsObj = this.encryptParamsObj(originalParams)
    }
    if (config.method === "get") {
      let str = ""
      if (encryptParamsObj) {
        Object.keys(encryptParamsObj).forEach(item => {
          str += `&${item}=${encryptParamsObj[item]}`
        })
      }
      config.url = `${config.url}${str ? `?${str.substr(1)}` : ""}`
      config.data = {}
      config.params = {}
    } else {
      config.data = qs.stringify(encryptParamsObj)
    }
  }
  // 合并参数
  mergeParams (publicParams, originalParams) {
    return Object.assign(publicParams, originalParams)
  }
  // 加密参数
  encryptParamsObj(params) {
    return encryptParams(params) // 加密参数并返回
  }
  init() {
    // request拦截器
    this.axios.interceptors.request.use(
      async (config) => {
        if(config.switchLoading) {
          vue.$engine.$startLoading()
        }
        // console.log(window.publicParams)
        const formateParams = this.formateParams(config.params) // 格式化参数
        // let publicParams = await generatePublicParam() // 生成公参
        let publicParams = window.publicParams
        /*
          * 全局变量—公用参数的入参位置：请求头headers | 请求体body
          * Global.publicPramasType : 'body' | 'headers'
        */
        if (Global.publicPramasType === "headers") {
          this.enterParamsToHeaders(publicParams, config.headers)
          this.enterParamsToBody(null, formateParams, config)
        } else {
          this.enterParamsToBody(publicParams, formateParams, config) 
        }
        return config;
      },
      (err) => {
        console.log(err);
        return Promise.reject(err);
      }
    );

    // response拦截器
    this.axios.interceptors.response.use(
      (response) => {
        const r = response.data;
        // const token = response.config.headers[TOKEN_KEY]
        // 从headers中取出token存入localStorage中
        // if (token) {
        //   setToken(token)
        // }
        vue.$engine.$endLoading()
				if (r.code === CODE_ERROR) {
					console.log('错误',r.message )
				} else if (NEED_LOGIN.includes(r.code)) {
					// 根据需要登录的code码和是否有token来判断，是否需要跳转登录页面
          router.push({ path: "/login" })
				} else {
					return r;
				}
      },
      (err) => {
        if (err.message.indexOf('timeout') !== -1) {
          console.log("请求超时")
        } else if (err && err.response) {
          let str = '出现网络错误,请重试'
          switch (err.response.status) {
            case 400:
              str = '请求错误'
              break
            case 401:
              str = '未授权，请登录'
              break
            case 403:
              str = '拒绝访问'
              break
            case 404:
              str = `请求地址出错: ${err.response.config.url}`
              break
            case 408:
              str = '请求超时'
              break
            case 500:
              str = '服务器内部错误'
              break
            case 501:
              str = '服务未实现'
              break
            case 502:
              str = '网关错误'
              break
            case 503:
              str = '服务不可用'
              break
            case 504:
              str = '网关超时'
              break
            case 505:
              str = 'HTTP版本不受支持'
              break
            default:
          }
          console.log(str)
        } else {
          console.log("出现网络错误,请重试")
        }
        return Promise.reject(err);
      }
    );
  }
  get(url, params, switchLoading = true) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: 'get',
        url,
        params,
        switchLoading
      }).then((res) => {
        resolve(res)
      })
    })
  }
  post(url, data, type) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: 'post',
        url,
        data
      }).then((res) => {
        resolve(res)
      })
    })
  }
}

export default new Axios();