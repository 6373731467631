import CryptoJS from "crypto-js";

/**
 * @desc DES加密
 * @param
 */
export function encryptByDES(message) {
  let keyHex = CryptoJS.enc.Utf8.parse("hw668F88"); //DES加密的key
  let ivHex = CryptoJS.enc.Utf8.parse("hw668F88");
  let encrypted = CryptoJS.TripleDES.encrypt(message, keyHex, {
    iv: ivHex,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}
/**
 * @desc DES解密
 * @param
 */
export function decryptByDES(ciphertext, desKey, iv) {
  let keyHex = CryptoJS.enc.Utf8.parse(desKey);
  let ivHex = CryptoJS.enc.Utf8.parse(iv);
  let decrypted = CryptoJS.DES.decrypt(
    {
      ciphertext: CryptoJS.enc.Utf8.parse(ciphertext),
    },
    keyHex,
    {
      iv: ivHex,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  let result_value = decrypted.toString(CryptoJS.enc.Utf8);
  return result_value;
}
/**
 * @desc BASE64加密
 * @param
 */
export function encryptByBASE64(message) {
  let wordArray = CryptoJS.enc.Utf8.parse(message);
  let base64 = CryptoJS.enc.Base64.stringify(wordArray);
  return base64;
}
/**
 * @desc BASE64解密
 * @param
 */
export function decryptBASE64(ciphertext) {
  let parsedWordArray = CryptoJS.enc.Base64.parse(ciphertext);
  let parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
  return parsedStr;
}

/**
 * @desc 加密请求参数
 * @param
 */
export function encryptParams(params) {
  if (!params) return;
  let paramsObj = {};
  Object.keys(params).map((item, index) => {
    // let encryptItemValue = encryptByBASE64(
    //   encryptByDES(JSON.stringify(params[item])),
    // );
    // let encryptItemValue = encryptByDES(JSON.stringify(params[item]));
    let encryptItemValue = encryptByDES(params[item] + "");
    paramsObj[item] = encryptItemValue;
  });
  // console.log(paramsObj);
  return paramsObj;
}

/**
 * @desc 埋点DES加密
 * @param {*} message
 * @returns
 */
export function buriedPointEncryptByDES(message) {
  let keyHex = CryptoJS.enc.Utf8.parse("tjkkk888");
  let ivHex = CryptoJS.enc.Utf8.parse("tjkkk888");
  let encrypted = CryptoJS.TripleDES.encrypt(message, keyHex, {
    iv: ivHex,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}
/**
 * @desc 加密埋点请求参数
 * @param {*} params
 * @returns
 */
export function buriedPointEncryptParams(params) {
  if (params && params["list"] && params["list"].length > 0) {
    let topic = ""; //topic必须和list项中content.model的值都相等才可以放在同一个数组，否则不能放在同一个数组
    let paramsObj = {};
    let list = [];
    Object.keys(params).map((item, index) => {
      if (item == "list") {
        topic = params["list"][0].content.model;
        params["list"].forEach((listItem) => {
          let obj = {};
          Object.keys(listItem).forEach((v) => {
            let content = "";
            if (v == "content") {
              content = encodeURIComponent(JSON.stringify(listItem[v]));
              obj[v] = content;
            }
            obj[v] = listItem[v];
          });
          list.push(encodeURIComponent(JSON.stringify(obj)));
        });
      } else {
        let encryptItemValue = buriedPointEncryptByDES(params[item] + "");
        paramsObj[item] = encryptItemValue;
      }
      paramsObj["list"] = encodeURIComponent(JSON.stringify(list));
    });
    // let str = "";
    // Object.keys(paramsObj).forEach((v, index) => {
    //   if (index == 0) {
    //     str += `${v}=${paramsObj[v]}`;
    //   } else {
    //     str += `&${v}=${paramsObj[v]}`;
    //   }
    // });
    console.log("paramsObj+++++++++", paramsObj);
    return { topic, message: JSON.stringify(paramsObj) };
  } else {
    return params;
  }
}