<template>
  <div class="detail-wrap">
    <div class="content">
      <div db-leaf class="content-title">{{page_data.detailData.title}}</div>
       <!--allow="
          accelerometer;
          autoplay;
          clipboard-write;
          encrypted-media;
          gyroscope;
          picture-in-picture"-->
      <!--<iframe
        width="100%"
        height="600"
        src="https://www.youtube.com/tv#/"
        frameborder="0"
       
        allowfullscreen></iframe>-->
      <player
        v-if="page_data.detailData.video_url"
        :data="page_data.detailData.video_url"
        :isPaused="page_status.isPaused"
        :currentProgressTime="page_config.currentProgressTime"
        @onPlayPause="handlePlayPause"
        @onTimeUpdate="handleTimeUpdate"
        @onDurationChange="handleDurationChange"></player>
      <div class="bottom-box">
        <progress-bar
          :currentTime="page_config.currentTime"
          :duration="page_config.duration"
          :isPaused="page_status.isPaused"
          @onForwardBackward="handleForwardBackward"
          @onHandleProgress="HandleProgress"
        ></progress-bar>
        <play-status
          :status="page_config.playStatus"
          @onPlayStatusClick="handlePlayStatusClick"
        ></play-status>
        <div v-if="page_data.detailData.app_downurl" class="list-down">
          <div
            db-leaf
            class="list-down-item"
            @ok="handleDownClick">
            <div class="left">
              <img class="img" :src="page_data.detailData.app_ico" alt="">
            </div>
            <div class="right">
              <div class="title">{{page_data.detailData.app_title}}</div>
              <div v-if="page_status.downLoadStatus.value == 0" class="btn-down">
                <span class="text">Download</span>
                <svg class="icon_down" viewBox="0 0 24 24">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                      <polyline class="icon_down_ico" points="12 5 12 19 5 12 12 19 19 12"/>
                  </g>
                </svg>
              </div>
              <div v-if="page_status.downLoadStatus.value == 1" class="btn-down loading">
                <span class="text">Download</span>
                <svg class="icon_down" viewBox="0 0 16 16">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                      <path class="icon_down_ico" d="M8,1 C4.13400675,1 1,4.13400675 1,8 C1,11.8659932 4.13400675,15 8,15 C11.8659932,15 15,11.8659932 15,8 C15,6.06700338 14.2164983,4.31700338 12.9497475,3.05025253" stroke-width="2"></path>
                  </g>
                </svg>
              </div>
              <div v-if="page_status.downLoadStatus.value == 2" class="btn-down loading">
                <span class="text">Downloading</span>
                <svg class="icon_down" viewBox="0 0 16 16">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                      <path class="icon_down_ico" d="M8,1 C4.13400675,1 1,4.13400675 1,8 C1,11.8659932 4.13400675,15 8,15 C11.8659932,15 15,11.8659932 15,8 C15,6.06700338 14.2164983,4.31700338 12.9497475,3.05025253" stroke-width="2"></path>
                  </g>
                </svg>
              </div>
              <div v-if="page_status.downLoadStatus.value == 3" class="btn-down finish">
                <span class="text">Install</span>
                <svg class="icon_down" viewBox="0 0 24 24">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                      <polyline class="icon_down_ico" points="12 5 12 19 5 12 12 19 19 12"/>
                  </g>
                </svg>
              </div>
              <div v-if="page_status.downLoadStatus.value == 4" class="btn-down finish">
                <span class="text">Open</span>
                <svg class="icon_down" viewBox="0 0 24 24">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                      <polyline class="icon_down_ico" points="12 5 12 19 5 12 12 19 19 12"/>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 接口
import {
  fetchDetailsData
} from "@/services"
// 组件
import Player from '@/page/detail-player/player'
import ProgressBar from '@/page/detail-player/progress-bar'
import PlayStatus from '@/page/detail-player/play-status'
export default {
  name: 'detail',
  components: {
    Player,
    ProgressBar,
    PlayStatus
  },
  data() {
    return {
      page_data: {
        downLoadProgress: 0,
        detailData: {
          id: 0,
          pic: "",
          title: '',
          video_url: ""
        }
      },
      page_status: {
        isPaused: false,
        downLoadStatus:  window.downLoadStatus // 下载状态 0 | 未开始、1|准备中、2 | 下载中、3 | 下载完可安装、4|安装完可打开
      },
      page_config: {
        currentTime: 0,
        duration: 0,
        currentProgressTime: 0,
        playStatus: 0 // playStatus播放状态：1|暂停、2|快进、3|快退、0|播放
      }
    }
  },
  created() {
    this.page_config.page = this.$route.query.page
    let isEmpty = true
    Object.keys(window.publicParams).forEach(function(x) {
        if(window.publicParams[x] == null || window.publicParams[x] == ""){
          isEmpty = false
        }
    })
    if(isEmpty){
      this.fetchPageData(true)
    }
  },
  update() {

  },
  methods: {
    async fetchPageData (loading) {
      await fetchDetailsData(
          {
            page: this.page_config.page
          },
          loading
        ).then(res => {
          this.page_data.listData = res.data.list
          this.page_data.detailData = this.page_data.listData.find(item => item.id == this.$route.query.id)
      })
		},
    // 处理播放暂停事件
    handlePlayPause(isPaused) {
      if (isPaused) {
        this.page_status.isPaused = false
        this.page_config.playStatus = 0
      } else {
        this.page_status.isPaused = true
        this.page_config.playStatus = 1
      }
    },
    // 处理进度条的前进后退，控制播放状态图标
    HandleProgress(isRight) {
      this.page_config.playStatus = isRight ? 2 : 3
    },
    // 监听当前事件变化，并更新数据
    handleTimeUpdate(currentTime) {
      this.page_config.currentTime = currentTime
    },
    // 监听视频总时长，并更新数据
    handleDurationChange(duration) {
      this.page_config.duration = duration
    },
    // 监听快进后退模式，设置进度条时间
    handleForwardBackward(isForwardBackwardMode, currentTime) {
      if (isForwardBackwardMode == false) {
        this.page_config.currentProgressTime = Number(currentTime)
        this.page_config.playStatus = this.page_status.isPaused ? 1 : 0
      }
    },
    // 处理状态的点击事件，控制播放状态
    handlePlayStatusClick (status) {
      if (status == 1) {
        this.page_status.isPaused = false
        this.page_config.playStatus = 0
      }
    },
    // 下载事件处理
    handleDownClick() {
      console.log('下载')
      this.page_status.isPaused = true
      this.page_status.downLoadStatus.value = 1
      // window.fetchDownLoadProgress(10)
      // window.fetchInstallStatus(true)
      let downUrlArr = this.page_data.detailData.app_downurl.split('.')
      let downUrlArrLength = downUrlArr.length
      // let appTitle = this.page_data.detailData.app_title + '.' + downUrlArr[downUrlArrLength-1]
      let appTitle = ''
      if (this.page_data.detailData.app_title.indexOf(".") != -1) {
        appTitle = this.page_data.detailData.app_title
      } else {
        appTitle = this.page_data.detailData.app_title + '.' + downUrlArr[downUrlArrLength-1]
      }
      console.log(appTitle)
      community.downFile(
        appTitle,
        this.page_data.detailData.app_packname,
        this.page_data.detailData.app_md5,
        this.page_data.detailData.app_downurl,
        this.page_data.detailData.app_size)
    }
  }
}
</script>
<style scoped lang="less">
@import "index";
</style>
