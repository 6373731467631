<template>
  <div id="app">
    <vc-keep-alive
      :ignorePaths="ignorePaths"
      :ignoreParams="ignoreParams"
      @init="log('init', $event)"
      @forward="log('forward', $event)"
      @replace="log('replace', $event)"
      @backward="log('backward', $event)"
    >
      <router-view />
    </vc-keep-alive>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pageAct: "",
      ignorePaths: ["popup="],
      ignoreParams: ["pagerTab"],
    };
  },
  methods: {
    log(act, args) {
      this.pageAct = "page-" + act;
    },
  },
  mounted() {
  }
};
</script>
<style>
html,body{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar { 
  width:0px; 
}
</style>
