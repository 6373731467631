import Global from '@/configs/global'

// 在全局变量中配置token在localStorage中的键名
const TOKEN_KEY = Global.TOKEN_KEY
/**
 * @desc 获取token
 */
export function getToken() {
  const token = window.localStorage.getItem(TOKEN_KEY)
  return token || ''
}
/**
 * @desc 存储token
 * @param value token值
 */
export function setToken(value) {
  window.localStorage.setItem(TOKEN_KEY, value)
}
/**
 * @desc 删除token
 */
export function removeToken() {
  window.localStorage.removeItem(TOKEN_KEY)
}