<template>
  <!--autoplay
  controls
  loop-->
  <div>
    <video
      ref="player"
      class="player"
      preload="auto"
      :src="data"
      autoplay
      muted
      @click="handleClickPlayer"
    />
  </div>
  
  <!--poster="http://hqimgv.dangcdn.com/health/2018-09-18/5ba06f4275e85.png"-->
</template>

<script>
  export default {
    props: {
      isPaused: {
        type: Boolean,
        default: false
      },
      data: {
        type: String,
        default: ''
      },
      currentProgressTime: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
      }
    },
    created() {
    },
    mounted() {
      let player = this.$refs.player
      this.$nextTick(() => {
        this.addListenerFun(player)
      });
      this.$once('hook:beforeDestroy', () => {
        this.removeListenerFun(player)
      })
    },
    methods:{
      // 播放器点击事件处理
      handleClickPlayer() {
        this.playPause()
      },
      // 暂停播放
      playPause() {
        let player = this.$refs.player
        if (player) {
          // 播放状态 true | false
          let isPaused = player.paused
          this.$emit("onPlayPause", isPaused)
        }
      },
      /* 监听 start */
      // 添加监听
      addListenerFun(player) {
        player.addEventListener("timeupdate", this.handleTimeUpdate)
        player.addEventListener("durationchange", this.handleDurationChange)
        player.addEventListener("pause", this.handlePause)
      },
      // 移除监听
      removeListenerFun(player) {
        document.removeEventListener("keyup", this.handleKeyOk)
        // player.pause()
        player.removeEventListener("timeupdate", this.handleTimeUpdate)
        player.removeEventListener("durationchange", this.handleDurationChange)
        player.removeEventListener("pause", this.handlePause)
      },
      // 监听键盘ok播放
      handleKeyOk(e) {
        let keycode = e.keyCode;
        if (keycode == 13) {
          this.playPause()
        }
        if (keycode == 38) {
          this.$refs.player.muted = false
          this.$refs.player.play()
        }
      },
      handlePause() {
        console.log(this.$refs.player.pause)
        console.log('监听暂停pause')
      },
      // 监听time更新事件
      handleTimeUpdate() {
        // console.log(this.$refs.range)
        // console.log('当前播放时间', this.$refs.player.currentTime)
        if(this.$refs.player.currentTime > 1) {
          // this.$refs.player.muted = false
          // this.$refs.player.play()
        }
        this.$emit("onTimeUpdate", this.$refs.player.currentTime)
      },
      handleDurationChange() {
        this.$nextTick(() => {
          this.$refs.player.currentTime = this.currentProgressTime
        })
        this.$emit("onDurationChange", this.$refs.player.duration)
      }
      /* 监听 end */
    },
    watch: {
      // 监听视频播放状态，控制是否播放
      isPaused: {
        handler(val, oldVal) {
          this.$nextTick(() => {
            document.addEventListener("keydown", this.handleKeyOk);
            // console.log(this.$refs.player)
            val ? this.$refs.player.pause() : this.$refs.player.play();
            setTimeout(function() {
              // val ? this.$refs.player.pause() : null
              // val ? this.$refs.player.pause() : this.$refs.player.play();
            }, 3000)
            
          });
        },
        immediate: true
      },
      currentProgressTime: {
        handler(val, oldVal) {
          this.$nextTick(() => {
             this.$refs.player.currentTime = val;
          })
        },
        immediate: true
      }
    }
  }
</script>

<style scoped lang="less">
  @import "index";
</style>