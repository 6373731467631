<template>
  <div class="play-status-content" @click="handlePlayStatusClick">
    <div
      class="play-status playing"
      :class="{'show': page_config.playStatus == 1}">
      <img
        class="img"
        :src="page_config.icon_pause"
        alt="暂停">
    </div>
    <div
      class="play-status forward"
      :class="{'show': page_config.playStatus == 2}">
      <img
        class="img"
        :src="page_config.icon_prev"
        alt="前进">
    </div>
    <div
      class="play-status backward"
      :class="{'show': page_config.playStatus == 3}">
      <img
        class="img"
        :src="page_config.icon_back"
        alt="后退">
    </div>
  </div>
</template>

<script>
  export default {
    name: 'status',
    props: {
      status: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        page_config: {
          playStatus: 0, // playStatus播放状态：1|暂停、2|快进、3|快退、0|播放
          icon_pause: require("@/assets/imgs/icon_pause_foc.png"), // 暂停图标
          icon_prev: require("@/assets/imgs/icon_right_nor.png"), // 前进图标
          icon_back: require("@/assets/imgs/icon_left_nor.png") // 后退图标
        }
      }
    },
    created () {
    },
    methods: {
      // 处理状态的点击事件，控制播放状态
      handlePlayStatusClick() {
        this.$emit("onPlayStatusClick", this.page_config.playStatus)
      }
    },
    watch: {
      status: {
        handler(val, oldVal) {
          this.page_config.playStatus = val
        },
        immediate: true,
      }
    }
  }
</script>

<style scoped lang="less">
  @import "index";
</style>
