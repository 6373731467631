import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import VcKeepAlive from "vc-keep-alive"
import DBPlugins from "@plugins/install"
import Global from '@/configs/global'
import global_listener from "@plugins/listener"


import "amfe-flexible"  // amfe-flexible根据屏幕动态改变根元素font-size

Vue.config.productionTip = false
// 设置全局变量
Vue.prototype.GLOBAL= Global
Vue.use(VcKeepAlive)
Vue.use(DBPlugins)
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
