import router from '@/router'
import Vue from 'vue'
document.addEventListener('keydown', (e) => {
    if (e.keyCode === 27 || e.keyCode === 461) {
        if (router.currentRoute.path === '/') {
            let _v = (new Vue())
            let focused_leaf = _v.$engine.findFocusedLeaf()
            focused_leaf.blur()
            _v.$engine.render()
            console.log('聚焦首个')
            return
        }
        router.go(-1)
    }
})